.consolidated-summary {
    width: 100%;
    font-family: gothamBook;
    text-align: center;
    
}
.consolidated-summary-2 {
  width: 99%; 

  font-family: gothamBook;
  text-align: center;
}
.summary-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
.consolidated-summary-2 .summary-container {

  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: 20px;
}
.table-heading {
  font-family: gothamBook;
  font-size: 10px;
  font-weight: 400;
  color: #3e3e3e;
}
.graph-labels-text {
  font-family: gothamBook;
  font-size: 12px;
  font-weight: 400;
  color: #3e3e3e;
}

/* .table-wrapper {
    width: 48%;
}
.table-wrapper-2{
  width: 40%;
} */

.summary-table , .summary-table-medium {
    /* table-layout: fixed; Ensures columns don't grow with large content */
    font-size: 0.9rem; /* Adjust font size for better table management */
   
}

.summary-table .ant-table-cell ,.summary-table-medium .ant-table-cell {
    white-space: normal; /* Allows text to wrap */
    /* word-break: break-word; */
    /* overflow: hidden; Ensures text that overflows the cell gets hidden */
    text-overflow: ellipsis; /* Adds ellipsis (...) for overflowed text */
}

.total-row .ant-table-cell {
    font-weight: bold;
}

.summary-table .ant-table-header th {
    border: none !important;
}

.summary-table .ant-table-thead > tr > th {
    background-color: #94A2F2 !important;
    border: none !important;
}

/* for border radius */
.summary-table .ant-table-container table>thead>tr:first-child >*:last-child {
    border-start-end-radius: 0px !important;
}

.summary-table table>thead>tr:first-child >*:first-child {
    border-start-start-radius: 0px !important;
}

.summary-table .ant-table-tbody > tr:last-child > td:first-child {
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;
}

.summary-table .ant-table-tbody > tr:last-child > td:last-child {
    border-bottom-right-radius: 0px !important;
}


/* padding: 10px 12px; */
.summary-table .ant-table-tbody>tr>td {
  padding: 10px 12px !important ;
}
/* For break up table */ 

.left-align {
    text-align: left !important;
}
.summary-table-medium .ant-table {
  max-width: 460px !important;
  min-width: 450px !important;
}
.summary-table-medium{
  overflow-x: auto; 
}

.summary-table  .ant-table th ,.summary-table-medium  .ant-table th  {
  text-align: left !important; /* Align headers to the left */
}

/* For summary-table-medium header styling */
.summary-table-medium .ant-table-header th {
  border: none !important;
}

.summary-table-medium .ant-table-thead > tr > th {
  background-color: #94A2F2 !important;
  border: none !important;
}

/* Border radius adjustments */
.summary-table-medium .ant-table-container table > thead > tr:first-child > *:last-child {
  border-start-end-radius: 0px !important;
}

.summary-table-medium table > thead > tr:first-child > *:first-child {
  border-start-start-radius: 0px !important;
}

.summary-table-medium .ant-table-tbody > tr:last-child > td:first-child {
  border-bottom-left-radius: 0px !important;
  border-top-left-radius: 0px !important;
}

.summary-table-medium .ant-table-tbody > tr:last-child > td:last-child {
  border-bottom-right-radius: 0px !important;
}

/* Cell padding */
.summary-table-medium .ant-table-tbody > tr > td {
  padding: 10px 12px !important;
}

/* Text alignment adjustments */
.summary-table-medium .left-align {
  text-align: left !important;
}

.summary-table-medium .ant-table th {
  text-align: left !important; /* Align headers to the left */
}


/* div class="ant-table ant-table-bordered css-dev-only-do-not-override-mzwlov" */
/* For table fixed width */
.summary-table .ant-table {
  min-width: 400px !important;
}
/* .ant-table .ant-table-bordered .css-dev-only-do-not-override-mzwlov {
  min-width: 400px !important;
} */

.consolidated-summary-2 .table-wrapper .ant-table-content {
  max-width: 700px;
  /* border: 1px solid brown; */
}


.consolidated-tab-container .table-wrapper-3 {
  /* border: 1px solid blue; */
  max-width: 1000px !important;
  flex: 60%;
}
.table-wrapper-3 .summary-table .ant-table {
  /* border: 1px solid red; */
  width: 100% !important;
  max-width: 810px !important;
}

/* @media (min-width: 1500px)  {
  .summary-table-medium .ant-table {
    max-width: 1200px !important;
  }
  .table-wrapper {
    width: 100%;
   
  }
 
  .consolidated-summary-2 .table-wrapper .ant-table-content {
    max-width: 1200px;
 
  }

  .consolidated-summary-2 .table-wrapper ,  .consolidated-summary-2 .table-wrapper-2 {
  
    width : 60% ;
    text-align: center;
  }
} */


@media (min-width: 1500px) {
  .summary-table-medium .ant-table {
    /* max-width: 780px !important; */
    max-width: 800px;
  }
  .table-wrapper {
    width: 100%;
   
  }
  .summary-table .ant-table {
   
    max-width: 100%;
  }
  .consolidated-summary-2 {
    flex: 50%;
    max-width: 50%;
   
    font-family: gothamBook;
    text-align: center;
  }
  .consolidated-summary-2 .table-wrapper .ant-table-content {

    max-width: 100%;
 
  }

  .consolidated-summary-2 .table-wrapper,
  .consolidated-summary-2 .table-wrapper-2 {
  
    width: 100%;
    text-align: center;
  }
  
  .consolidated-tab-container .table-wrapper-3 {
  
    max-width: 870px !important;
    flex: 60%;
  }
  .table-wrapper-3 .summary-table .ant-table {

    width: 100% !important;
    max-width: 810px !important;
  }
  
}
/* Mobile-specific styles (below 1024px) */
@media (max-width: 1023px) {
  .summary-container {
    flex-direction: column;
  }

  .table-wrapper {
    width: 100%;
  }

  .summary-table {
    font-size: 0.8rem; /* Reduce font size further for mobile */
  }
}
.pdf-view .summary-table-medium .ant-table {
  max-width: none; /* Remove any max-width */
  width: auto; /* Allow full width */
  overflow: visible; /* Ensure overflow does not hide content */
}

.pdf-view .summary-table-medium .ant-table {
  width: 100% !important; /* Ensure the table takes full width */
  max-width: none !important; /* Remove any max-width */
  overflow: visible !important; /* Prevent overflow issues */
}

.pdf-view .table-wrapper {
  width: auto !important; /* Allow the wrapper to expand */
  overflow: visible !important; /* Ensure contents are visible */
}
.pdf-view .table-wrapper-2 {
  width: auto !important; /* Allow the wrapper to expand */
  overflow: visible !important; /* Ensure contents are visible */
}