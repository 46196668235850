.full-width-container {
  width: 100%;
}

.calendar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.calendar-header {
  display: flex;
  justify-content: space-between;

  margin-bottom: 16px;
  width: 99%;
  margin-right: 30px;
}

.month-year {
  display: flex;
  align-items: center;
  font-size: 20px;

  font-family: "gothamBook";
  font-weight: 600;
}

.calendar-grid {
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
}

.cell {
  display: inline-block;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  width: 55px; /* Adjust the width as needed */
  box-sizing: border-box;

  margin-bottom: 20px;
}

.empty-cell {
  visibility: hidden;
}

.highlight-cell {
  background-color: #294799;
  color: white;

  font-size: 10px;
  text-align: center;
  border-radius: 20px;
  margin-bottom: 20px;
}

.day {
  font-size: 13px;
  margin-bottom: 15px;
}

.date {
  font-size: 14px;
  font-weight: bold;
}

.other-content {
  width: 100%;
  padding: 20px;
  text-align: center;
}

/* table.css */
.ant-tabs-tab {
  color: #294799 !important;
}

.ant-tabs-ink-bar {
  background-color: #294799 !important;
}

/* table.css */
.ant-table-thead > tr > th {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
