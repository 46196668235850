.consolidated-tab-container {
  border: 1px solid #aaaaaa;
  border-radius: 7px;
  margin: auto;
  margin-left: 26px;
  margin-right: 10px;
  padding: 20px;
}

/* For table  */
.financials-table {
  width: 50% !important;
  /* border :  1px solid #C8C4C4 !important ; */
  /* border-radius: 7px; */
}
/* Remove borders from header */
.financials-table .ant-table-header {
  border: none !important; /* Remove border from header */
}

.financials-table .ant-table-header th {
  border: none !important; /* Remove border from header cells */
}

/* Optional: Set a background color for better visibility */

/* Ensure cells have no border */
/* .financials-table .ant-table-cell {
  border: none !important; Remove cell borders
  border-right: 1px solid #c8c4c4 !important;
} */

.financials-table .ant-table-thead > tr > th{
    background-color: #94A2F2 !important;
    border: none !important;
}

/* Apply border to summary rows */
/*  */

/* .ant-table-wrapper .ant-table-summary >tr >td
{
    border : 1px solid #c8c4c4 !important; 
} */
