.custom-collapse .ant-collapse-header {
  /* background-color: #f0f2f5; */
  font-size: 20px;
  color: #3e3e3e;
  font-family: gothamBook;
  font-weight: 400;
  /* text-align: center; */
  align-items: center;
}
.custom-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-expand-icon {
  margin: auto !important ;
}
.custom-collapse .ant-collapse-content {
  border: 1px solid #d9d9d9;
  background-color: #fafafa;
}

.custom-collapse .ant-collapse-item {
  border-radius: 5px;
  margin-bottom: 8px;
}

